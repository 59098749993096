/** Override Material styles */

@use '../../app/theme/style/variables';

.mat-card {
  margin-bottom: variables.$gutter;
}

.mat-accordion {
  display: block;
  margin-bottom: variables.$gutter;
}

.mat-list-base {
  position: relative;
}

.mat-checkbox,
.mat-radio-button,
.mat-slide-toggle {
  margin-right: variables.$gutter * 0.5;

  [dir='rtl'] & {
    margin-right: auto;
    margin-left: variables.$gutter * 0.5;
  }
}

.form-field-full {
  .mat-form-field {
    width: 100%;
  }
}

.mat-step-header .mat-step-icon {
  height: 45px;
  width: 45px;
  background-color: variables.$mpr-stepper-step-icon-defalt-color;
  color: black;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #fff;
  color: black;
  border: 1px solid #707070;
}
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: map-get(variables.$md-mpr-accent, 700);
  color: black;
}

mat-stepper {
  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header
    .mat-step-label {
    padding: 13px 0 0 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #0b2949;
    &.mat-step-label-selected {
      font-weight: 600;
    }
  }

  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
    top: 48px;
    border-color: variables.$mpr-stepper-linecolor;
  }

  &.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 48px;
    border-color: variables.$mpr-stepper-linecolor;
  }

  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir='rtl']
    &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::before,
  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir='rtl']
    &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::after {
    width: calc(50% - 21px);
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
  .mat-horizontal-stepper-header-container {
    padding: 0px 16rem;
  }

  .mat-step-icon-state-edit + .mat-step-label-active {
    .mat-step-text-label {
      color: variables.$mpr-stepper-linecolor;
      text-decoration: underline;
    }
  }
}

[class*='mtx-popover-above'] .mtx-popover-direction-arrow {
  width: 1em;
  color: white;
}

.mtx-popover-direction-arrow:after {
  color: map-get(variables.$md-mpr-primary, 900);
  color: #08046b !important;
}

[class*='mtx-popover-below'] .mtx-popover-direction-arrow:after,
[class*='mtx-popover-above'] .mtx-popover-direction-arrow:after {
  color: white;
}

[class*='mtx-popover-below'] .mtx-popover-direction-arrow:after,
[class*='mtx-popover-above'] .mtx-popover-direction-arrow:after {
  border-right-color: transparent;
  border-left-color: transparent;
}

.mtx-popover-direction-arrow:before,
.mtx-popover-direction-arrow:after {
  border-right-color: transparent;
  border-left-color: transparent;
}

.mtx-popover-panel {
  box-shadow: none;
  border: 1px solid map-get(variables.$md-mpr-primary, 900);
}

.mtx-popover-panel {
  .mpr-tooltip-title-bold {
    font-weight: bold;
  }
  .mtx-popover-content {
    font-size: 12px;
    color: map-get(variables.$md-mpr-primary, 900);
  }
}

.mpr-hide-form-field-label-onfocus {
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }
}

.mpr-form {
  .mpr-form-block {
    .mpr-form-block-field {
      .mat-form-field-type-mat-input.mat-form-field-appearance-legacy,
      .mat-form-field-type-mat-chip-list.mat-form-field-appearance-fill {
        &.mat-form-field-disabled {
          .mat-form-field-flex {
            background-color: red;
          }
        }

        .mat-form-field-wrapper {
          .mat-form-field-flex {
            border: 1px solid variables.$mpr-border-color-selects;
            font-size: 16px;
            padding: 5px;
            background-color: white;
            border-radius: 0;
          }

          .mat-form-field-infix {
            border-top: 0px;
          }

          .mat-form-field-underline {
            height: 0;
            display: none;
          }
        }

        &.mat-form-field-invalid {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              border-color: variables.$mpr-error-font-color;
            }
          }
        }

        &.mpr-metadata-dataTags-chip-list.mat-form-field-hide-placeholder
          .mat-input-element::placeholder {
          color: variables.$mpr-placeholder-color;
        }
      }
    }
  }
}

.mpr-tabs-container {
  .mat-tab-group {
    .mat-tab-header {
      width: 500px;
      border-bottom: none;

      .mat-tab-label-container {
        .mat-tab-labels {
          .mat-tab-label {
            border-bottom: 0px solid;
            font-weight: bold;
            color: #0b2949;
          }
        }
      }
    }
  }
}
.mat-tab-label:hover {
  color: #0b2949 !important;
}

.mpr-search-box {
  .mat-form-field-flex {
    border-color: variables.$mpr-error-font-color;
    border: 1px solid;
    padding: 5px 5px 5px 15px;
    min-width: 250px;

    .mat-form-field-infix {
      border-top: 0;
    }
  }

  .mat-form-field-underline {
    height: 0;
    display: none;
  }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) {
  .mat-slide-toggle-bar {
    background-color: variables.$mpr-stepper-linecolor;
  }

  .mat-slide-toggle-thumb {
    background-color: map-get(variables.$mat-colors, white);
  }
}

.mpr-mat-input-field {
  .mat-form-field-flex {
    border-color: variables.$mpr-error-font-color;
    border: 1px solid;
    padding: 5px 5px 5px 15px;

    .mat-form-field-infix {
      border-top: 0;
    }
  }

  .mat-form-field-underline {
    height: 0;
    display: none;
  }
}
.mat-expansion-indicator:after {
  color: #189394 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  margin: 0px 5px;
  padding: 2px;
  background: #f1b51c !important;
}

.workspace-wrapper-container {
  .mat-tab-label .mat-tab-label-content {
    font: normal normal bold 14px/27px Montserrat;
  }
  .mat-tab-label-active {
    color: #0b2949;
    opacity: 1;
  }
}

.mpr-mat-input-field .mat-form-field-flex {
  align-items: center;
}

.mpr-s3-file-selection-checkbox{
  .mat-checkbox-inner-container{
    margin-right: 0;
  }
}