@use '../../app/theme/style/variables';

.mpr-tiles-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  a {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 33%;
    cursor: pointer;
  }
}

.mpr-tiles {
  height: 224px;
  border: 0.5px solid #707070;
  border-left: 2px solid #f1b51c;
  padding: 30px;
  margin: 5px;
  width: 100%;

  &-title {
    font-size: 13px;
    font-weight: bolder;
    text-transform: uppercase;
    padding: 15px 0;

    span {
      border: 2px solid #f1b51c;
      font-size: 11px; //getting 508 error for small font. Do not revert to 8 px.
      padding: 7px 8px;
      border-radius: 15px;
      background-color: #f1b51c3b;
      margin-left: 10px;
    }
  }

  &-img img {
    width: 38px;
    height: 48px;
  }
}

.mpr-tiles:hover:nth-child(odd) {
  background-color: #0b2949;

  .mpr-tiles-title {
    color: #f1b51c;
  }

  .mpr-tiles-desc {
    color: #fff;
  }
}

.mpr-tiles:hover:nth-child(even) {
  background-color: #0b2949;

  .mpr-tiles-title {
    color: #f1b51c;
  }

  .mpr-tiles-desc {
    color: #fff;
  }
}
