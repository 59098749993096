@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .matero-branding-name {
    color: mat.get-color-from-palette($foreground, text);
  }
}
