@use "../../app/theme/style/variables";
.cdk-overlay-pane {
  .mtx-popover-panel {
    font-family: variables.$font-family-sans-serif !important;
    color: variables.$mpr-primary-variant-color-1;
    font-size: 15px !important;
    font-weight: 500;
    border: 0.5px solid variables.$mpr-primary-variant-color-1;
  }
  .mtx-popover-panel[class*="mtx-popover-below"] {
    margin-top: calc(0.5em);
  }
  .mtx-popover-panel[class*="mtx-popover-above"] {
    margin-bottom: calc(0.5em);
  }
}

.mtx-tooltip {
  background: #fff;
  color: #000 !important;
  max-width: 650px !important;
  border: 1px solid #000;
  margin-top: 3px !important;
}
