@use '../../app/theme/style/variables';

.mpr-note-text {
  font-size: 14px;
  color: variables.$mpr-note-text-color;
  text-align: center;
}
.mpr-utc-time-zone-text {
  text-decoration: underline;
  font-size: 12px;
}
.mpr-info-text {
  font-size: 12px;
  color: variables.$mpr-note-text-color;
  text-transform: none;
}
.mpr-info-icon {
  height: 16px;
  width: 16px;
  font-size: 16px;
  color: variables.$mpr-primary-variant-color-1;
}

.mpr-note-dashed-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px dashed variables.$mpr-divider-color;
}

.mpr-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mpr-bold-text {
  font-weight: bold;
}

.mpr-buttons-row {
  flex: 0 1 50px;
}
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  &.mpr-button {
    border-radius: 30px;
    font-weight: bold;
    font-size: 13px;
    line-height: 45px;
    text-transform: uppercase;
    &.mat-button-disabled {
      opacity: 50%;
      pointer-events: none;
      cursor: default;
    }
    &.mpr-green-button {
      border-color: variables.$mpr-branding-text-color;
      color: variables.$mpr-branding-text-color;
    }
    &.mpr-blue-button {
      border-color: map-get(variables.$md-mpr-primary, 900);
      color: map-get(variables.$md-mpr-primary, 900);
    }
    &.mpr-yellow-button {
      background-color: map-get(variables.$md-mpr-accent, 700);
      color: map-get(variables.$md-mpr-primary, 900);
    }
    &.mpr-cancel-button {
      margin-right: 10px;
    }
  }
}
.mpr-width-percentage {
  &-100 {
    width: 100%;
  }
  &-95 {
    width: 95%;
  }
}
.mpr-width-px {
  &-400 {
    width: 400px;
  }
  &-300 {
    width: 300px;
  }
  &-160 {
    width: 160px;
  }
  &-140 {
    width: 140px;
  }
  &-100 {
    width: 100px;
  }
  &-50 {
    width: 50px;
  }
}
.mpr-btn-transparency {
  background-color: transparent;
  border: 0px;
}
.mpr-underline {
  text-decoration: underline;
}
.mpr-big-contents {
  height: 100%;
  padding: 10%;
  color: map-get(variables.$md-mpr-primary, 900);
  .mpr-page-header {
    font-size: 28px;
    font-weight: 400;
    margin: 0px;
  }
  .mpr-buttons-row {
    margin-top: 25px;
  }
}
a.disabled {
  pointer-events: none;
  cursor: default;
  background-color: #ffffff;
  opacity: 0.6;
}

.mpr-cursor-pointer {
  cursor: pointer;
}

.mpr-outline:focus {
  outline: 2px;
  outline-style: solid;
  outline-color: #0b2949;
}

.mpr-outline:hover {
  outline: 2px;
  outline-style: solid;
  outline-color: #0b2949;
}

.mpr-word-break-all {
  word-break: break-all;
}

.mpr-upppercase-transform {
  text-transform: uppercase;
}

.mpr-service-now-link {
  text-decoration: underline;
  font: normal normal bold 14px/22px Montserrat;
  letter-spacing: 0px;
  color: #046b5b;
}

.mpr-list-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mpr-list-action {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.mpr-list {
  font: normal normal bold 16px/19px Montserrat;
  letter-spacing: 0px;
  color: map-get(variables.$md-mpr-primary, 900);
  text-transform: uppercase;
  padding-left: 5px;
}

.mpr-action-button {
  background: map-get(variables.$md-mpr-accent, 700) 0% 0% no-repeat padding-box;
  border-radius: 28px;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: map-get(variables.$md-mpr-primary, 900);
  text-transform: uppercase;
  display: flex;
  height: 38px;
  align-items: center;
  padding: 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid;
}

.mpr-divider {
  border: 1px dashed #70707080;
  margin: 14px 0;
}

.mpr-justify-content-end {
  justify-content: end;
}

.mpr-justify-content-center {
  justify-content: center;
}

.mpr-visibility-visible {
  visibility: visible;
}

.mpr-visibility-hidden {
  visibility: hidden;
}

.mpr-align-items-center {
  align-items: center;
}

.mpr-justify-content-space-between {
  justify-content: space-between;
}

.mpr-d-flex-dir-col {
  flex-direction: column;
}

.mpr-content-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  .mpr-content-loader-text {
    letter-spacing: 0.04rem;
    white-space: pre;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: repeating-linear-gradient(to right, #ffeb3b, #ff9800);
    background-size: 750% auto;
    background-position: 0 100%;
    animation: gradient 20s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    font: normal normal normal 28px Montserrat;
    color: map-get(variables.$md-mpr-primary, 900);
  }
}

.mpr-no-style {
  all: unset !important;
}

.mpr-hidden-labels {
  display: none;
}
