@use '../../app/theme/style/variables';

.mpr-mat-select-field.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    border-top: 0.5em solid transparent;
    padding: 0.8em 0 0.8em 0;
  }

  .mat-select-arrow-wrapper {
    background: url(/assets/images/drop-down.svg) no-repeat right #ddd;
    /*To change default icon with provided image*/
    background-position-x: 98%;
    /*Adjust according to width of dropdown*/
    background-color: transparent;
    background-position-y: 35%;

    .mat-select-arrow {
      opacity: 0;
      margin: 0 7px;
    }
  }

  &.ng-invalid.ng-touched {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-color: #f44336;
    }
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap {
    border-radius: 0px;
    border-color: #414b66;
  }
}

.mpr-select-option-panel {
  margin-top: 30px;

  &.mat-primary .mat-pseudo-checkbox-checked,
  &.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #046b5c;
  }

  .mpr-placeholder-option {
    color: variables.$mpr-font-color1;
  }
}

.mat-select-panel {
  border-radius: 0px !important;
}

.mpr-select-source-spinner {
  margin-left: 10px;

  .mat-progress-spinner {
    display: inline;
  }
}

.mpr-mat-select-field .mat-select-multiple .mat-select-value {
  max-height: 32px;
  overflow-y: auto;
}

// outine css changes
