@use '../../app/theme/style/variables';

.mpr-buttons-row {
  flex: 0 1 50px;
}
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  &.mpr-button {
    border-radius: 30px;
    font-weight: bold;
    font-size: 13px;
    line-height: 45px;
    text-transform: uppercase;
    &.mat-button-disabled {
      opacity: 50%;
    }
    &.mpr-green-button {
      border-color: variables.$mpr-branding-text-color;
      color: variables.$mpr-branding-text-color;
    }
    &.mpr-blue-button {
      border-color: map-get(variables.$md-mpr-primary, 900);
      color: map-get(variables.$md-mpr-primary, 900);
    }
    &.mpr-yellow-button {
      background-color: map-get(variables.$md-mpr-accent, 700);
      color: map-get(variables.$md-mpr-primary, 900);
    }
    &.mpr-cancel-button {
      margin-right: 10px;
    }
    &.mpr-green-border-button {
      border: 1px solid;
      border-color: map-get(variables.$md-mpr-primary, 900);
      color: map-get(variables.$md-mpr-primary, 900);
    }
  }
}
