.mpr-mat-menu-custom-view {
    .mat-menu-content {
        height: auto ; 
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .mat-menu-item {
        line-height:35px;
        height:35px;
        display: flex;
        align-items: center;
        padding:7px 15px;
    }
}   