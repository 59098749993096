@use 'variables';
@use 'badge' as *;
@use 'scrollbar' as *;

.badge {
  @include badge();
}

.scrollbar-thin {
  @include scrollbar-thin();
}

.scrollbar-none {
  @include scrollbar-none();
}
