/* You can add global styles to this file, and also import other style files */
@use 'app/theme/style';
@use 'styles/themes';
@use 'styles/custom';
@use 'styles/helpers';


.flyout {
  font-weight: 600;
  font-size: 16px;
}
